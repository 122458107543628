import * as crypto from './aes';
import env from "./env";
import i18n from "../../i18n";

const encryptKey = env.encryptKey

export function isNotEmpty(value) {
  return value !== null && value !== '' && value !== undefined;
}


export function decrypt (orderInfo) {
  // var url = this.$route.query.orderInfo;
  var all = decodeURIComponent(orderInfo);
  // var result = all.replace('orderInfo=', '');
  var query = crypto.decrypt(all, encryptKey)
  var vars = query.split("&");

  let varMap = new Map();
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    varMap.set(pair[0], pair[1]);
  }
  console.log(varMap)
  return varMap;
}

export function GetRequestParam() {
  let url = location.href.split("?")[1].split("&"); //获取url中"?"符后的字串

  let query = {};
  url.forEach(item => {
    query[item.split("=")[0]] = decodeURIComponent(item.split("=")[1]);  //转码
  });
  return query;
}


export function getValue (map, queryName) {
  if (map != null && map.size > 0 && map.has(queryName)) {
    return map.get(queryName);
  } else {
    var query = decodeURI(window.location.search.substring(1));
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == queryName) { return pair[1]; }
    }
    return null;
  }
}


export function changeLanguage (map) {
  let queryName = "currency"
  let currency = ""
  if (map != null && map.size > 0 && map.has(queryName)) {
    currency = map.get(queryName)
  } else {
    var query = decodeURI(window.location.search.substring(1));
    var vars = query.split("&");
    if (vars == query) {
      query = decrypt(query);
      currency = query.get(queryName)
    }
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == queryName) {
        currency = pair[1]
      }
    }
  }

  if (currency === "IDR") {
    localStorage.setItem('lang', 'id-IDN')
  } else if (currency === "THB") {
    localStorage.setItem('lang', 'th-THA')
  } else if (currency === "MYR") {
    localStorage.setItem('lang', 'my-MYS')
  } else if (currency === "PHP") {
    localStorage.setItem('lang', 'en-US')
  } else if (currency === 'BRL') {
    localStorage.setItem('lang', 'pt-BR')
  } else if (currency === 'MXN') {
    localStorage.setItem('lang', 'es-MX')
  } else {
    localStorage.setItem('lang', 'en-US')
  }
  console.log("App.vue" , localStorage.getItem('lang'))
  i18n.locale = localStorage.getItem('lang')
}

export function getLanguageByCurrency(currency){
  switch(currency){
    case "IDR":
      return "id-ID";
    case "MYR":
      return "ms-MY";
    case "VND":
      return "vi-VN";
    case "PHP":
      return "fil-PH";
    case "THB":
      return "th-TH";
    case "BRL":
      return "pt-BR";
    case "MXN":
      return "es-MX";
    default:
      return "en-US";
  }
}


export const PaymentBrand = {
  TIKIPAY: { name:"tikipay",  title:"TiKiPay" ,head:"Tiki"},
  ALLOPAY: { name:"allopay",  title:"AlloPay" ,head:"Allo"},
  TENAPAY: { name:"tenapay",  title:"TenaPay" ,head:"Tena"},



  getPaymentBrand: function() {
    let paymentBrand = localStorage.getItem('paymentBrand')
    if(paymentBrand == PaymentBrand.ALLOPAY.name){
      return PaymentBrand.ALLOPAY
    } else if(paymentBrand == PaymentBrand.TENAPAY.name){
      return PaymentBrand.TENAPAY
    } else {
      return PaymentBrand.TENAPAY
    }
  },
}
